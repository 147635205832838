<template>
    <div class="customer-invite">
        <div class="mb-4">
            <router-link :to="`/bank/customers/all`">
                <img src="/media/buying/icons/arrow-left.svg" alt="">
                {{ $t("Back to Customer List") }}
            </router-link>
        </div>
        <div class="row custom-row">
            <div class="col-lg-5">
                <CustomersInviteForm
                    :customers="customers"
                    @onShowUserList="handleShowUserList"
                    @onSuccessInvite="handleSuccessInvite"
                />
            </div>
            <div class="col-lg-7">
                <CompanyUserList
                    :loading="loading"
                    v-if="customers && customers.customerUsersData"
                    :companyUserData="customers.customerUsersData"
                />

                <CustomersInviteList
                    v-else
                    :loading="loading"
                    :customers="customers"
                />
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import CustomersInviteForm from "@/buying-teams/pages/bank/customers/sections/CustomersInviteForm";
import CustomersInviteList from "@/buying-teams/pages/bank/customers/sections/CustomersInviteList";
import CompanyUserList from "@/buying-teams/pages/bank/customers/sections/CompanyUserList";

export default {
    name: "CustomerInvite",
    components: {
        CompanyUserList,
        CustomersInviteList,
        CustomersInviteForm,
        CustomLoader,
    },
    data() {
        return {
            customers: null,
            loading: true,
            inviteLoading: false,
            companyUserData: null,
        };
    },
    computed: {
        bank() {
            return store.getters.bank;
        },
        user() {
            return store.getters.currentUser;
        }
    },
    created() {
        if (!this.user.can(BankUserPermissionTypeEnum.INVITE_CUSTOMERS)) {
            this.$router.push('/dashboard');
        }
        setCurrentPageBreadcrumbs({
            title: this.bank.name,
            logo: this.bank.icon_path
        });

        this.getBankInvitations();
    },
    methods: {
        getBankInvitations() {
            this.loading = true;
            store.dispatch('fetchBankInvitations').then((res) => {
                this.customers = res;
            }).finally(() => {
                this.loading = false;
            })
        },
        handleShowUserList(data) {
            if (data) {
                this.loading = true;
                store.dispatch('fetchBankInvitationsUsers', { business_id: data }).then(res => {
                    this.customers.setCustomerUsersData(res);
                }).finally(() => {
                    this.loading = false;
                })
            } else {
                this.customers.setCustomerUsersData(null);
            }
        },
        handleSuccessInvite() {
            this.getBankInvitations();
        }
    },
};
</script>

<style lang="scss">
.customer-invite {
    @media (max-width: 1330px) {
        .custom-row {
            flex-direction: column;
            gap: 24px;

            & > div {
                width: 100%;
            }
        }
    }
}
</style>
