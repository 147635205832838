<template>
    <div class="customer-invite-list">
        <div class="customer-invite-list__header">
            <h1>{{ $t('Sent Invitations') }}</h1>
            <span>{{ (customers && customers.invitationsData) ? customers.invitationsData.length : 0 }}</span>
        </div>
        <div class="customer-invite-list__info">
            <inline-svg src="/media/buying/icons/info_outined.svg" />
            <span>{{ $t('Fully accepted companies disappear from this list in DAYS') }} <b>30 {{ $t('days') }}.</b></span>
        </div>

        <div class="customer-invite-list__content">
            <CustomLoader v-if="loading" />
            <template v-else>
                <el-collapse v-if="customers && customers.invitationsData && customers.invitationsData.length">
                    <el-collapse-item
                        v-for="(item, index) in customers.invitationsData"
                        :key="index"
                        :name="index + item.business_name"
                    >
                        <template #title>
                            <div class="customers-header">
                                <h4>{{ item.business_name }}</h4>
                                <div class="customers-header__footer">
                                    <div class="invited-email">
                                        <inline-svg src="/media/buying/icons/email.svg" />
                                        <span>{{ $t('Invited Mails') }}:</span>
                                        <strong>{{ item.invites.length }}</strong>
                                    </div>
                                    <div class="invite-accepted">
                                        <b>{{ item.accepted_count }}/{{ item.invites.length }}</b> {{ $t('Accepted') }}
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="customers-body">
                            <div
                                class="customers-email-item"
                                :class="{'is-loading': item.is_update_loading}"
                                v-for="(item, inx) in item.invites"
                                :key="`${index}-${inx}`"
                            >
                                <span>{{ item.email }}</span>
                                <div class="customers-email-item__right">
                                    <p>{{ $t('Sent on') }} <b>{{ item.invite_sent_at }}</b></p>
                                    <div class="email-status" :class="item.status.className">
                                        {{ $t(item.status.text) }}
                                    </div>
                                    <button
                                        class="email-resend"
                                        :disabled="item.isResendDisabled || item.is_update_loading"
                                        @click="updateBankInvitations(item, operationEnum.RESEND)"
                                    >
                                        <inline-svg src="/media/buying/icons/refresh-icon.svg" width="18" height="18" />
                                    </button>
                                    <button
                                        class="email-remove"
                                        :disabled="item.isDeleteDisabled || item.is_update_loading"
                                        @click="updateBankInvitations(item, operationEnum.DELETE)"
                                    >
                                        <inline-svg src="/media/buying/icons/close.svg" width="18" height="18" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
                <template v-else>
                    <div class="text-center fs-6 mt-5">{{ $t('No Data') }}</div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { CustomerInvitationsContainer } from "@/store/models/bank/CustomerInvitationsContainer";
import store from "@/store";

const operationEnum = {
    RESEND: 'resend',
    DELETE: 'delete',
}

export default {
    name: "CustomersInviteList",
    components: {
        CustomLoader
    },
    data() {
        return {
            operationEnum
        }
    },
    props: {
        customers: {
            type: [Object, CustomerInvitationsContainer],
            default: null,
        },
        loading: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        updateBankInvitations(item, operation) {
            const payload = {
                email: item.email,
                operation,
            }
            item.setLoading();
            store.dispatch('updateBankInvitations', payload).finally(() => {
                item.setLoading(false);
            });
        }
    }
}
</script>

<style lang="scss">
.customer-invite-list {
    background: #FFF;
    border-radius: 17px;
    &__header {
        padding: 26px 24px;
        display: flex;
        align-items: center;
        h1 {
            color: #4F4F4F;
            font-size: 17px;
            font-weight: 500;
            margin-right: 12px;
            margin-bottom: 0;
        }
        span {
            border-radius: 3px;
            background: #ECECEC;
            padding: 1px 4px;
            color: #000;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
        }
    }
    &__info {
        padding: 18px 24px;
        background: #FAFAFA;
        display: flex;
        align-items: center;
        svg {
            margin-right: 8px;
        }
        span {
            color: #435BF4;
            font-size: 14px;
            font-weight: 500;
            b {
                font-weight: 700;
            }
        }
    }
    &__content {
        padding-left: 24px;
        height: 618px;
        overflow-y: auto;

        .el-collapse {
            border: none !important;
        }
        .el-collapse-item {
            &__header {
                height: auto;
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: space-between;
                .el-collapse-item__arrow {
                    margin: revert;
                }
            }
            &__header {
                border-bottom: 2px solid rgba(0, 0, 0, 0.06);
            }
            &__wrap {
                border-bottom: 2px solid transparent;
            }
            &__content {
                padding: 16px 24px 16px 0;
            }
        }
        .customers-header {
            width: calc(100% - 32px);
            padding: 16px 32px 16px 0;
            h4 {
                color: #4F4F4F;
                font-size: 18px;
                font-weight: 700;
                line-height: 27px;
                margin-bottom: 12px;
            }
            &__footer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                line-height: initial;
                .invited-email {
                    svg path {
                        fill: #2A2A2A;
                    }
                    span {
                        color: #4F4F4F;
                        font-size: 12px;
                        font-weight: 300;
                        margin-left: 8px;
                        margin-right: 8px;
                    }

                    strong {
                        border-radius: 3px;
                        background: #ECECEC;
                        color: #000;
                        font-size: 12px;
                        font-weight: 600;
                        padding: 1px 2px;
                    }
                }
                .invite-accepted {
                    color: #4F4F4F;
                    font-size: 12px;
                    font-weight: 300;
                    b {
                        font-weight: 700;
                    }
                }
            }
        }

        .customers-body {
            border-radius: 12px;
            background: #F7F7F7;
            padding: 7px 20px;
            .customers-email-item {
                display: flex;
                padding: 6px 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.10);
                span {
                    color: #000;
                    font-size: 12px;
                    font-weight: 500;
                }
                &__right {
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    .email-status {
                        border-radius: 4px;
                        font-size: 12px;
                        font-weight: 800;
                        padding: 2px 10px;
                        height: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #e0e0e0;
                        min-width: 78px;
                        &.invite-status-accepted {
                            color: #0A882D;
                            background-color: rgba(95, 251, 40, 0.14);
                        }
                        &.invite-status-pending {
                            color: #E6B25F;
                            background: #FEF9E7;
                        }
                        &.invite-status-bounced {
                            color: #ED7B74;
                            background: #FDF0F0;
                        }
                    }
                    p {
                        color: #4F4F4F;
                        font-size: 12px;
                        font-weight: 300;
                        margin-bottom: 0;
                        b {
                            font-weight: 500;
                        }
                    }
                    button {
                        padding: 0;
                        background: transparent;
                        border: none;
                        outline: none;
                        svg path {
                            fill: #000000;
                        }
                        &:disabled {
                            opacity: 0.3;
                        }
                        &.email-remove:not(:disabled):hover {
                            svg path {
                                fill: #E22D21;
                            }
                        }
                        &.email-resend:not(:disabled):hover {
                            svg path {
                                fill: #435BF4;
                            }
                        }
                    }
                }

                &.is-loading {
                    opacity: .5;
                }
            }
        }
    }

    @media (max-width: 600px) {
        &__content .customers-body .customers-email-item {
            flex-direction: column;
            &__right {
                margin-left: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 4px;
                p {
                    margin-right: auto;
                }
            }
        }
    }
}
</style>
