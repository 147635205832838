<template>
    <div class="customer-invite-form">
        <div class="customer-invite-form__header">
            <h3>{{ $t('Invite new users') }}</h3>
        </div>

        <div class="customer-invite-form__tabs">
            <el-radio-group v-model="activeTab" size="large">
                <el-radio-button :label="TABS.NEW_CUSTOMER">{{ $t(TABS.NEW_CUSTOMER) }}</el-radio-button>
                <el-radio-button :label="TABS.EXISTING_CUSTOMER">{{ $t(TABS.EXISTING_CUSTOMER) }}</el-radio-button>
            </el-radio-group>
        </div>

        <div class="customer-invite-form__item">
            <div class="invite-form-label">{{ $t('Company Name') }}*</div>
            <input
                v-if="activeTab === TABS.NEW_CUSTOMER"
                type="text"
                v-model="companyName"
                :placeholder="$t('Enter the company name here')"
            >

            <template v-if="activeTab === TABS.EXISTING_CUSTOMER">
                <el-select
                    v-model="existingCustomerName"
                    filterable
                    :placeholder="$t('Search for the company here')"
                    size="large"
                >
                    <el-option
                        v-for="item in existingCustomers"
                        :key="item.id"
                        :label="item.business_name"
                        :value="item.id"
                    />
                </el-select>
                <a
                    href=""
                    @click.prevent="$emit('onShowUserList', existingCustomerName)"
                    v-if="existingCustomerName"
                >{{ $t('View user list') }}</a>
            </template>
        </div>

        <el-form :model="formData" :rules="emailsRules" ref="formRef" @submit.prevent="submitForm()">
            <div class="customer-invite-form__item">
                <div class="invite-form-label">{{ $t('Add Email') }}*</div>
                <div class="add-email-input">
                    <el-form-item prop="emailField">
                        <input
                            type="text"
                            v-model="formData.emailField"
                            @input="emailErrorMsg = ''"
                            placeholder="johndoe2018@kolt.com"
                        >
                    </el-form-item>
                    <el-button :loading="emailAddLoading" @click.prevent="submitForm()">
                        <span v-if="!emailAddLoading">+</span>
                    </el-button>
                </div>
            </div>
        </el-form>

        <div class="added-emails">
            <div class="added-emails__header">
                <h4>{{ $t('Added Emails') }}</h4>
                <span>{{ addedEmails.length }}</span>
            </div>

            <div class="added-emails__content" v-if="addedEmails.length">
                <div class="email-item" v-for="email in addedEmails">
                    <span>{{ email }}</span>
                    <div class="email-item-btn" @click="removeEmail(email)">
                        <inline-svg src="/media/buying/icons/close.svg" width="20" height="20" />
                    </div>
                </div>
            </div>
            <div class="added-emails__no-data" v-else>
                <img src="/media/buying/undraw_email_campaign.svg" alt="">
                <h2>{{ $t('Put in company name and add e-mails to it') }}</h2>
            </div>
        </div>

        <div class="send-invite-wrapper">
            <div class="send-invite">
                <el-button
                    class="btn main-btn"
                    :disabled="buttonDisabled"
                    :loading="submitLoading"
                    @click="handleInvite"
                >
                    {{ $t('Invite New Customer') }}
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>

import store from "@/store";
import { CustomerInvitationsContainer } from "@/store/models/bank/CustomerInvitationsContainer";
import { Actions } from "@/store/enums/StoreEnums";
import NotificationService from "@/buying-teams/services/NotificationService";

const TABS = {
    NEW_CUSTOMER: "New Customer",
    EXISTING_CUSTOMER: "Existing Customer",
}

export default {
    name: "CustomersInviteForm",
    emits: ['onShowUserList', 'onSuccessInvite'],
    props: {
        customers: {
            type: [Object, CustomerInvitationsContainer],
            default: null,
        },
    },
    data() {
        return {
            TABS,
            companyName: '',
            existingCustomerName: '',
            formData: {
                emailField: '',
            },
            addedEmails: [],
            submitLoading: false,
            activeTab: TABS.NEW_CUSTOMER,
            emailAddLoading: false,
            emailErrorMsg: '',
        }
    },
    computed: {
        emailsRules() {
            var validateEmailExists = (rule, value, callback) => {
                if (this.emailErrorMsg) {
                    callback(new Error(this.$t(this.emailErrorMsg)));
                } else {
                    callback();
                }
            };
            return {
                emailField: [
                    {
                        required: true,
                        message: this.$t('Please input Email'),
                        trigger: ['blur', 'change'],
                    },
                    {
                        type: 'email',
                        message: this.$t('Please input correct email'),
                        trigger: ['blur', 'change'],
                    },
                    {
                        validator: validateEmailExists,
                        trigger: ['blur', 'change']
                    },
                ]
            }
        },
        existingCustomers() {
            const allData = new Map();
            const invitedCustomers = this.customers ? this.customers.invitationsData : [];
            const allCustomers = store.getters.bank.customers ? store.getters.bank.customers : [];

            invitedCustomers.forEach(inv => {
                allData.set(inv.business_id, {...inv, id: inv.business_id});
            });
            allCustomers.forEach(inv => {
                allData.set(inv.id, inv);
            });

            return Array.from(allData.values());
        },
        buttonDisabled() {
            return !this.addedEmails.length ||
                (this.activeTab === TABS.NEW_CUSTOMER && !this.companyName) ||
                (this.activeTab === TABS.EXISTING_CUSTOMER && !this.existingCustomerName)
        }
    },
    methods: {
        async submitForm() {
            this.$refs.formRef.validate((valid) => {
                if (valid) {
                    if (!this.addedEmails.includes(this.formData.emailField)) {

                        this.emailAddLoading = true;
                        store.dispatch(Actions.CHECK_EMAIL, { email: this.formData.emailField })
                            .then(({ data }) => {
                                if (data && data.data && !data.data.is_registered_user) {
                                    this.addedEmails.push(this.formData.emailField);
                                    this.formData.emailField = '';
                                } else if (data && data.data && data.data.is_registered_user) {
                                    this.emailErrorMsg = 'The email you are trying to add here already exists.';
                                    this.$refs.formRef.validate();
                                }
                            })
                            .finally(() => {
                                this.emailAddLoading = false;
                            })
                    } else {
                        this.formData.emailField = '';
                    }
                }
            })
        },
        removeEmail(email) {
            this.addedEmails = this.addedEmails.filter(e => e !== email);
        },
        handleInvite() {
            this.submitLoading = true;
            const payload = {
                emails: this.addedEmails,
            }
            if (this.activeTab === TABS.NEW_CUSTOMER) {
                payload.business_name = this.companyName;
            } else {
                payload.business_id = this.existingCustomerName;
            }
            store.dispatch('inviteBankCustomer', payload).then(res => {
                NotificationService.swalCustom({
                    title: this.$t('Success'),
                    text: this.$t('Invitation successfully sent'),
                    icon: "success"
                });
                this.existingCustomerName = '';
                this.companyName = '';
                this.addedEmails = [];
                this.$emit('onSuccessInvite');
            }).finally(() => {
                this.submitLoading = false;
            })
        }
    },
    watch: {
        activeTab() {
            this.companyName = '';
            this.existingCustomerName = '';
            this.$emit('onShowUserList');
        }
    }
}
</script>

<style lang="scss">
.customer-invite-form {
    border-radius: 17px;
    background: #FFF;
    box-shadow: 0px 9px 29px 0px rgba(0, 0, 0, 0.06);
    padding: 26px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 43px;
        h3 {
            color: #3E3E3E;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 12.654px;
            margin-bottom: 0;
        }
    }

    &__tabs {
        margin-bottom: 24px;
        width: 100%;
        .el-radio-group {
            width: 100%;
            display: flex;
            padding: 4px 5px;
            border-radius: 7px;
            background: #F2F2F2;
            .el-radio-button {
                width: 100%;
                .el-radio-button__inner {
                    width: 100%;
                    background: transparent;
                    border: none;
                    color: #838383;
                    text-align: center;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    box-shadow: none;
                    border-radius: 5px;
                }
                &.is-active {
                    .el-radio-button__inner {
                        background: #FFF;
                        color: #000;
                    }
                }
            }
        }
    }

    &__item {
        margin-bottom: 20px;
        .invite-form-label {
            color: #4F4F4F;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 12px;
        }
        > a {
            color: #435BF4;
            font-size: 12px;
            font-weight: 500;
            line-height: 158.023%;
            margin-top: 4px;
            display: block;
            width: max-content;
        }
        input {
            color: #000000;
            font-size: 16px;
            font-weight: 400;
            border-radius: 5.282px;
            background: #F6F6F6;
            width: 100%;
            padding: 14px;
            outline: none;
            height: 45px;
            border: 1px solid transparent;
            &::placeholder {
                color: #8B8B8B;
            }
            &:focus {
                border-color: #435BF4;
            }
        }
        .el-form-item {
            width: 100%;
        }
        .add-email-input {
            display: flex;
            gap: 9px;
            button {
                border-radius: 5.282px;
                background: rgba(67, 91, 244, 0.05);
                height: 45px;
                width: 57px;
                color: #435BF4;
                border: none;
                font-size: 24px;
                transition: .3s;
                &:not(:disabled):hover {
                    opacity: 0.5;
                }
            }
        }
        .el-select {
            .el-input__inner {
                color: #000000;
                font-size: 16px;
                font-weight: 400;
                border-radius: 5.282px;
                background: #F6F6F6;
                width: 100%;
                padding: 14px;
                outline: none;
                height: 45px;
                border: 1px solid transparent;

                &::placeholder {
                    color: #8B8B8B;
                    font-size: 16px;
                    font-weight: 400;
                }
                &:focus {
                    border-color: #435BF4;
                }
            }
        }
    }

    .added-emails {
        min-height: 230px;
        display: flex;
        flex-direction: column;
        &__header {
            display: flex;
            align-items: center;
            h4 {
                color: #4F4F4F;
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 0;
                margin-right: 10px;
            }
            span {
                border-radius: 3px;
                background: #ECECEC;
                color: #000;
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                padding: 2px 4px;
            }
        }
        &__content {
            margin-top: 16px;
            margin-bottom: 16px;
            border-radius: 12px;
            background: #F7F7F7;
            padding: 7px 20px;
            .email-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 32px;
                padding: 6px 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.10);
                span {
                    color: #000;
                    font-size: 12px;
                    font-weight: 500;
                }
                .email-item-btn {
                    cursor: pointer;
                    svg path {
                        fill: #585858;
                    }
                    &:hover {
                        svg path {
                            fill: #E22D21;
                        }
                    }
                }
            }
        }
        &__no-data {
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 57px;
            img {
                margin-bottom: 24px;
            }
            h2 {
                color: #D4D4D4;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                line-height: 29.124px;
                margin-bottom: 0;
            }
        }
    }

    .send-invite-wrapper {
        margin-left: -26px;
        margin-right: -26px;
        margin-bottom: -26px;
        padding: 16px 24px 32px 24px;
        border-top: 1px solid #F2F2F2;
        display: flex;
        align-items: center;
        justify-content: center;
        .send-invite {
            position: relative;
            .main-btn {
                height: 31px;
                min-height: auto;
                padding: 8px 30px !important;
                font-size: 12px;
                font-weight: 700;
                display: flex;
                align-items: center;
                i {
                    padding-right: 0;
                }
            }
        }
    }

    @media (max-width: 600px) {
        &__tabs .el-radio-group .el-radio-button .el-radio-button__inner {
            font-size: 14px;
            padding: 10px 16px;
        }
    }
}
</style>
