<template>
    <div class="company-user-list">
        <CustomLoader v-if="loading" height="400px" />

        <template v-else>
            <div class="company-user-list__header">
                <h1>{{ $t('Company user list') }}</h1>
            </div>
            <div class="company-user-list__search">
                <inline-svg src="/media/buying/icons/search-black.svg" />
                <input type="text" v-model="search" :placeholder="$t('Search Customer')">
            </div>

            <div class="company-user-list__data">
                <div class="company-user-item" v-for="(user, index) in displayData" :key="index">
                    <img :src="user.icon_path" alt="">
                    <div class="company-user-item__info">
                        <h4>{{ user.getFullName }}</h4>
                        <span>{{ user.email }}</span>
                    </div>

                    <div class="status-wrapper">
                        <span v-if="user.status === CustomerInviteStatuses.PENDING">{{ $t('Pending') }}</span>
                    </div>
                </div>
            </div>

            <CustomPagination
                v-if="!search"
                :current-page="page"
                :total="companyUserData.length"
                :page-size="pageSize"
                :hide-on-single-page="false"
                @currentPageChange="handleCurrentChange"
                @pageSizeChange="handlePageSizeChange"
            />
        </template>
    </div>
</template>

<script>
import { Constants } from "@/core/config/constants";
import { CustomerInviteStatuses } from "@/store/enums/bank/CustomerInviteStatuses";
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";

export default {
    name: "CompanyUserList",
    components: {
        CustomLoader,
        CustomPagination
    },
    props: {
        companyUserData: {
            type: Array,
            default: []
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            search: '',
            Constants,
            page: 1,
            pageSize: 7,
            CustomerInviteStatuses
        }
    },
    computed: {
        filteredData() {
            return this.search ? this.companyUserData.filter((item) => {
                return item.getFullName.toLowerCase().includes(this.search.toLowerCase()) || item.email.toLowerCase().includes(this.search.toLowerCase())
            }) : this.companyUserData;
        },
        displayData() {
            return this.search ? this.filteredData : this.companyUserData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.page = val;
        },
        handlePageSizeChange(size) {
            this.pageSize = size
        }
    }
}
</script>

<style lang="scss">
.company-user-list {
    background: #FFF;
    border-radius: 17px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .custom-pagination {
        margin-top: auto;
        padding: 14px 22px;
    }
    .el-pagination__sizes, .el-pagination__jump {
        display: none !important;
    }
    &__header {
        padding: 26px 24px;
        display: flex;
        align-items: center;
        h1 {
            color: #4F4F4F;
            font-size: 17px;
            font-weight: 500;
            margin-right: 12px;
            margin-bottom: 0;
        }
    }
    &__search {
        margin: 4px 22px 18px 22px;
        border-radius: 29px;
        background: #FAFAFA;
        max-width: 338px;
        padding-left: 49px;
        position: relative;
        input {
            width: 100%;
            height: 40px;
            border: none;
            background: transparent;
            outline: none;
            font-size: 16px;
            font-weight: 400;
            color: #000;
            &::placeholder {
                color: #C4C4C4;
            }
        }
        svg {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &__data {
        .company-user-item {
            padding: 18px 22px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            &:hover {
                background: #f9fafa;
            }
            img {
                width: 24px;
                height: 24px;
                margin-right: 17px;
                border-radius: 50%;
                object-fit: cover;
            }
            &__info {
                h4 {
                    color: #2B2B2B;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 158.023%;
                    margin-bottom: 0;
                }
                span {
                    color: #435BF4;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 120%;
                }
            }
            .status-wrapper {
                margin-left: auto;
                span {
                    border-radius: 4px;
                    font-size: 12px;
                    font-weight: 800;
                    padding: 2px 10px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 78px;
                    color: #E6B25F;
                    background: #FEF9E7;
                }
            }
        }
    }
}
</style>
