export enum BankUserPermissionTypeEnum {
    INVITE_USER = "pa_invite_user",
    EDIT_USER = "pa_edit_user",
    PROMOTE_DEMOTE_USER = "pa_promote_demote_user",
    DELETE_USER = "pa_delete_user",
    EDIT_BANK_SETTINGS = "pa_edit_bank_settings",
    CHANGE_USER_GROUP_PERMISSIONS = "pa_change_user_group_permissions",
    CHANGE_ADMIN_PERMISSIONS = "pa_change_admin_permissions",
    INVITE_ADMIN = "pa_invite_admin",
    EDIT_ADMIN = "pa_edit_admin",
    DELETE_ADMIN = "pa_delete_admin",
    COMMENT_IDEA = "pu_comment_idea",
    COMMENT_REQUEST = "pu_comment_request",
    CREATE_FEEDBACK_SESSION = "pu_create_feedback_session",
    INVITE_CUSTOMERS = "pu_invite_customer",
    OPEN_CLOSE_REQUEST_IDEA = "pu_open_close_request",
}
